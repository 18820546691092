import React, { useState } from "react";
import Styles from "./DropDownList.module.css";
import arrowDown from "../../../images/arrowDown.svg";
import arrowUp from "../../../images/arrowUp.svg";

const DropDownList = ({ options }) => {
  const [open, setOpen] = useState([]);
  const handleOpen = (index) => {
    setOpen((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };
  return (
    <div className={Styles.DropDownList}>
      {options.map((option, index) => {
        return (
          <>
            <div key={index} className={Styles.DropDownContent}>
              <div onClick={() => handleOpen(index)}>
                <h5>{option.title}</h5>
                {open?.includes(index) ? (
                  <img src={arrowUp} width={20} alt="arrow-up" />
                ) : (
                  <img src={arrowDown} width={20} alt="arrow-down" />
                )}
              </div>
              {open?.includes(index) ? option.text : null}
            </div>
            {options.length - 1 !== index ? (
              <div className={Styles.Line}></div>
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default DropDownList;
