import React from "react";
import { CircularProgress, Box } from "@mui/joy";

const ProgressCircularBar = ({ value, variant, title }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        flexWrap: "wrap",
        flexDirection: "column",
      }}
    >
      <p
        style={{
          margin: 0,
          fontWeight: 500,
          fontSize: "14px",
        }}
      >
        {title}
      </p>
      <CircularProgress
        determinate
        sx={{
          "--CircularProgress-trackThickness": "8px",
          "--CircularProgress-progressThickness": "8px",
          "--CircularProgress-size": "80px",
        }}
        slots={{
          ".MuiCircularProgress-root": {
            ".MuiCircularProgress-svg": {
              ".MuiCircularProgress-progress": {
                color: "rgb(255, 255, 255)",
              },
            },
          },
        }}
        size="lg"
        value={value}
        color={variant}
      >
        <p>{value}%</p>
      </CircularProgress>
    </Box>
  );
};

export default ProgressCircularBar;
