import React from "react";
import Navigation from "../../../components/Layout/Navigation/Navigation.js";
import DropDownList from "../../../components/Layout/DropdownList/DropDownList.js";
import Styles from "./Faq.module.css";
import Seo from "../../../utils/SeoHelmet/Seo.js";
import { useNavigate } from "react-router-dom";
const FaqPage = () => {
  const navigate = useNavigate();
  const optionsList = [
    {
      title: "What is Rule of 40?",
      text: (
        <p>
          The Rule of 40 is a principle that states a software company's
          combined revenue growth rate and profit margin should equal or exceed
          40%.
        </p>
      ),
    },
    {
      title: "Why is the Rule of 40 important?",
      text: (
        <p>
          The Rule of 40 is a key financial metric used to evaluate the
          performance and sustainability of software-as-a-service (SaaS)
          companies and other high-growth businesses. It provides a balanced
          view of a company's growth and profitability, helping investors and
          operators assess whether the business is on a solid financial footing.
          A score of 40% or higher indicates that a company is striking a
          healthy balance between growth and profitability. High-growth
          companies often sacrifice profitability to scale rapidly, while more
          mature companies focus on profitability at the expense of growth. The
          Rule of 40 ensures a company is not overly focused on one at the
          expense of the other. Companies with strong growth but negative
          margins may struggle to sustain operations without consistent funding.
          Companies with strong profitability but low growth may lose market
          relevance. The Rule of 40 provides a quick check on whether a
          company's financial strategy is sustainable over the long term. SaaS
          companies are often valued on their ability to scale recurring revenue
          efficiently. The Rule of 40 has become a standard benchmark for
          comparing SaaS businesses, particularly in the eyes of investors. A
          company meeting or exceeding the Rule of 40 is often seen as a
          well-managed business. Investors use this metric to identify companies
          with the potential to deliver consistent returns without excessive
          risk. The Rule of 40 is straightforward and easy to calculate, making
          it a popular tool for quick assessments without delving into complex
          financial models.
        </p>
      ),
    },
    {
      title: "What is the drawback to using the Rule of 40? ",
      text: (
        <p>
          The Rule of 40 does not account for events that may impact the
          company, altering pricing and sentiment about the company. Such events
          include leadership changes, product launches, government actions,
          short seller reports, activist moves, and lawsuits, to name a few.
          It's important to pair investing with ongoing event monitoring to
          avoid being caught in a stock should a negative event occur. Using AI
          tools for stock investing and event monitoring (
          <a
            target="_blank"
            href="https://www.levelfields.ai/"
            rel="noreferrer"
          >
            LevelFields
          </a>
          ) can greatly improve investment returns.
        </p>
      ),
    },
    {
      title: "Why was this Rule of 40 application created?",
      text: (
        <p>
          We created the Rule of 40 application to make it easier for
          self-directed and professional investors to quickly locate emerging
          growth companies without having to rely on the news or market pundits.
          LevelFields is dedicated to the mission of leveling the playing field
          for all investors, enabling anyone to achieve financial independence.
        </p>
      ),
    },
    {
      title: "How Can I request more data?",
      text: (
        <p>
          We provide data for thousands companies but, if you need more, feel
          free to request with the{" "}
          <strong
            onClick={() => navigate("/contact-us")}
            style={{ cursor: "pointer", fontWeight: 500 }}
          >
            request button
          </strong>
          .
        </p>
      ),
    },
  ];
  return (
    <div className={Styles.Page}>
      <Seo description={"Rule of 40 F.A.Q."} />
      <Navigation />
      <div className={Styles.PageContent}>
        <div className={Styles.PageTitle}>
          <h3>F.A.Q.</h3>
          <p>
            Our Rule of 40 tool is designed to identify companies that meet this
            criteria.
          </p>
        </div>
        <DropDownList options={optionsList} />
      </div>
    </div>
  );
};

export default FaqPage;
