import { Box, Slider, styled } from "@mui/material";
import React from "react";

const StyledSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
}));

const CustomSlider = ({ value, setValue, handleMouseUp }) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const valueLabelFormat = (value) => {
    if (value === -1000) return `Lower: ${value}`;
    if (value === 1000) return `Higher: ${value}`;
    return `${value}`;
  };

  return (
    <Box sx={{ width: "calc(100% - 80px)" }}>
      <StyledSlider
        getAriaLabel={() => "Rule of forty range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        step={5}
        min={-100}
        max={100}
        aria-labelledby="non-linear-slider"
        valueLabelFormat={valueLabelFormat}
        onChangeCommitted={handleMouseUp}
      />
    </Box>
  );
};

export default CustomSlider;
