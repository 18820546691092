import React, { useState } from "react";
import Styles from "./Navigation.module.css";
import Button from "../../Common/Button/Button.js";
import { useLocation, useNavigate } from "react-router-dom";
import hamburger from "../../../images/hamburger.svg";
import RightSideModal from "../../Common/RightSideModal/RightSideModal.js";
import useWindowDimensions from "../../../hooks/useWindowDimensions.js";
import logo from "../../../images/logo.png";

const Navigation = ({ setOpenModal }) => {
  const navigate = useNavigate();
  const [rightSideModal, setRightSideModal] = useState(false);
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();

  const handleNavigateAbout = () => {
    window.open("https://www.levelfields.ai/about", "_blank");
  };
  const handleNavigateNews = () => {
    window.open("https://fintechnews.levelfields.ai/");
  };

  const navOptions = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "FAQ",
      path: "/faq",
    },
    {
      name: "Contact us",
      path: "/contact-us",
    },
    {
      name: "About Us",
      func: handleNavigateAbout,
    },
    {
      name: "Blog",
      path: "/blog",
    },
    {
      name: "News",
      func: handleNavigateNews,
    },
    {
      name: "Login",
      path: "/login",
    },
  ];

  return (
    <div className={Styles.Navigation}>
      <div className={Styles.NavigationContent}>
        <div className={Styles.LeftSideNav}>
          <div
            className={Styles.Hamburger}
            onClick={() => setRightSideModal(true)}
          >
            <img src={hamburger} alt="hamburger" width={20} />
          </div>
          {width < 700 ? (
            <div className={Styles.LogoWrapper}>
              <h2 onClick={() => navigate("/")}>Rule of 40</h2>
              <a
                href="https://www.levelfields.ai/about"
                target="_blank"
                rel="noreferrer"
              >
                Powered by <img src={logo} width={60} alt="logo" />
              </a>
            </div>
          ) : null}
        </div>
        {width > 700 ? (
          <div className={Styles.LogoWrapper}>
            <h2 onClick={() => navigate("/")}>Rule of 40</h2>

            <a
              href="https://www.levelfields.ai/about"
              target="_blank"
              rel="noreferrer"
            >
              Powered by <img src={logo} width={70} alt="logo" />
            </a>
          </div>
        ) : null}
        <div className={Styles.RightSideNav}>
          {pathname === "/" ? (
            <>
              <div className={Styles.Buttons}>
                <Button
                  style={{
                    height: "40px",
                  }}
                  variant={"black"}
                  onClick={() => setOpenModal((prev) => !prev)}
                >
                  Download
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <RightSideModal
        open={rightSideModal}
        setOpen={setRightSideModal}
        options={navOptions}
      />
    </div>
  );
};

export default Navigation;
