import React from "react";
import { onClick } from "../Logic/FilterLogic.js";
import Styles from "./Filters.module.css";

const Filters = ({ type, options, setFilters, obj, style, filters }) => {
  return (
    <>
      {type === "select" ? (
        <select
          style={style ? style : null}
          className={Styles.Select}
          onChange={(e) => onClick(obj.key, e.target.value, setFilters)}
          value={filters ? filters[0] : ""}
        >
          {options.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.name}
              </option>
            );
          })}
        </select>
      ) : null}
    </>
  );
};

export default Filters;
